import React from 'react'

function IndexOverview() {
    return (
        <div>
            <section className="content-section-over">
            <div className="container">
              <div className="row align-items-center">
                
                <div className="col-lg-6">
                  <figure className="side-image">
                    <img src="/images/overview-1.jpg" alt="Image" />
                  </figure>
                </div>

                <div className="col-lg-6">
                 <div className="col-md-6">
                  <div className="section-title">
                  <h2>Overview</h2>
                  </div>
                </div>

                  <div className="side-content">
                   
                    <p>
                    We at VVE focus on providing bespoke personalized services to ensure that our clients are not constrained in terms of project execution and management.
                    </p>
                    <p>
                    We take care of all the leg work from planning, design, approvals, construction, management, sourcing, etc. so that the client is relaxed to focus on their needs and preference.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
            
        </div>
    )
}
export default IndexOverview
