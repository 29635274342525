import React from "react"

import Layout from "../components/Layout"
import IndexHeader from "../components/IndexHeader"
import IndexOverview from "../components/IndexOverview"
import IndexHighlights from "../components/IndexHighlights"
import IndexServices from "../components/IndexServices"
import IndexHighlightAlt from "../components/IndexHighlightAlt"
import App from "../components/App"

const Home = () => {
  return (
    <Layout pageTitle="Home">
      <div>
        <IndexHeader />
        <IndexOverview />
        
        <section class="footer-bar">
	  <div class="container">
    <div class="row">
      <div class="col-8-lg">
      <div className="section-title text-left d1">
                    <h2 className="d1">Principals</h2>
                  </div>
                  <div className="row">
                   <div className="col-6">
                   <figure class="logo-item" > <img src="/images/us_buildings.png" alt="Image" /> </figure>
          <a href="https://usbuildingsgroup.com/us/" target="_blank" class="button">website<i class="lni lni-arrow-right"></i></a> 
		   
                   </div>   
                   <div className="col-6">
                   <figure class="logo-item" > <img src="/images/kingspan-logo.png" alt="Image" /> </figure>
          <a href="https://www.kingspan.com/group/" target="_blank" class="button">website<i class="lni lni-arrow-right"></i></a> 
		   
                   </div>   
                 </div>
		  
       
		
		</div>
    
		
    </div>
  
  </div>
  
		
</section>
  

        <IndexHighlights />
        {/* <IndexHighlightAlt/> */}
        <div className="sizeBox"></div>
        <IndexServices />

        <section className="content-section-map">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title-our">
                  <h2></h2>
                </div>
              </div>

              <div className="col-lg-8">
                <figure className="side-image">
                  <App />
                </figure>
              </div>

              <div className="col-lg-4  align-items-cente">
                <div className="section-title">
                  <h2></h2>
                </div>
                <div className="side-content">
                  <div className="side-content">
                    <h5>Our Presence</h5>

                    <ul className="custom-list">
                      <li>
                        <i class="lni lni-checkmark"></i> Srilanka
                      </li>
                      <li>
                        <i class="lni lni-checkmark"></i> Maldives
                      </li>
                      <li>
                        <i class="lni lni-checkmark"></i> Bangladesh
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default Home
